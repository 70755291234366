
.main-sidebar {
    .sidebar {
        position: fixed;
        top: 63px;
        width: 240px;
        height: 100%;
        border-right: 1px solid #ccc;
        z-index: 1;
        transition: all 0.3s ease;
        overflow: hidden;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li a {
                display: block;
                padding: 10px;
                color: #555;
                text-decoration: none;
                transition: all 0.3s ease;

                & :hover {
                    background-color: #fff;
                    color: $primary-color;
                }
            }
        }
    }

    .sidebar.show {
        left: -16rem;
    }

    .delete-icon {
        font-size: 14px;
        cursor: pointer;
    }
}

.main-sidebar-icon {
    font-size: 20px;
    cursor: pointer;
  }


.sidebar-icon-close{
    position: absolute;
    top: 380px;
    left: -17px;
    background: #1e88e5;
    color: #fff;
    height: 70px;
    width: 35px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 100px;
    z-index: 10;
    transition: 0.3s ease-in;
    
   .sidebar-icon-close i {
    position: absolute;
    top: 27px;
    left: 19px;
    font-size: 13px;
  }

    &:hover{
    box-shadow: 0.063rem 0 1.25rem 0 #1b80da;
  }
}

.sidebar-icon-close i {
    position: absolute;
    top: 27px;
    left: 19px;
    font-size: 13px;
  }


 .sidebar-icon-open{
    display: none !important;
  }
  .sidebar:hover .sidebar-icon-open{
    position: absolute !important;
    background-color: #1e88e5;
    color: #fff;
    display: block !important;
    right: -17px;
    z-index: 99999;
    top: 380px;
    height: 70px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;
    border-radius: 100px;
  }
  
  .sidebar-icon-open{
    transition: all 0.3s ease;
    &:hover{
    box-shadow: 0.063rem 0 1.25rem 0 #1b80da;
    }
  }
  
  .sidebar-icon-open i {
    position: absolute;
    top: 26px;
    left: 6px;
    font-size: 13px;
  }

  .query-header{
    padding: 12px !important;
  }

  .sidenav{
    background-color: #fff;
    transition: all .1s ease;
    position: fixed;
    z-index: 11;
    top: 62\px;
    width: 3rem;
    height: 100vh;

    .logo-container{
        display: flex;
        align-items: center;
        padding: 1rem 0.938rem 0.88rem 0.238rem;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
        .logo{
            background-color: #fff;
            text-align: center;
            width: 2.5rem;
            min-width: 2.5rem;
            border-radius: 0.313rem;
            color: #0d6efd;
            // padding: 0.313rem;
            font-size: 20px;
            font-weight: 900;
            cursor: pointer;
            border: none;
        }

        .logo-text{
            margin-left: 3.5rem;
            font-size: 24px;
            font-weight: 700;
            color: #fff;
            top: 8px;
            position: absolute;
        }
        .btn-close{
            margin-left: 3rem;
            cursor: pointer;
            width:2rem;
            border-radius: 50%;
            background-color: transparent;
            border: none;
            .fa-solid
            {
                color: #fff;
                font-size: 24px;
            }
        }
    }
}

.sidenav-collapsed{
    width: 240px;
}
.sidenav-nav {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 10px 0;
    align-items: center;
    height: calc(100% - 3.65rem);
    overflow-y: auto;
    overflow-x: hidden;
    cursor: pointer;
    border-right: 1px solid rgba(0, 0, 0, .12);
    
    .sidenav-nav-item {
        width: 100%;
        padding: 5px 0;
        .sidenav-nav-link {
            // border-left: 5px solid transparent;
            display: flex;
            align-items: center;
            padding: 8px 0;
            color: rgba(0, 0, 0, 0.66) !important;
            text-decoration: none;
            transition: all .5s ease;
            white-space: nowrap;

            .sidenav-link-icon {
                font-size: 18px;
                width: 18px;
                height: 18px;
                min-width: 2rem;
                margin: 0 8px;
                text-align: center;
                line-height: 1;
                color: #777777;
            }

            .sidenav-link-text {
                font-size: 15px;
            }

            &:hover:not(.active) {
                background-color: rgb(235 235 235) !important;
                .sidenav-link-icon, .sidenav-link-text {
                    color: #000 !important;
                }
            }
        }

        .active {
            background-color: #f1f5fe;
            border-left: 5px solid dodgerblue;
            .sidenav-link-icon, .sidenav-link-text {
                color: $primary-color !important;
            }
        }
    }
}

.custom-seperator{
    border-top: 1px solid #a4adad;
    margin: 5px 10px;
    padding: 0 !important;
}
.sidenav-collapsed.sidenav-collapsed-admin{ width: 240px; height: 100vh;}
.sidenav-collapsed-admin{
   width: 0;
}