/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import "bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/font/bootstrap-icons.css");
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "src/assets/scss/_authentication.scss";
@import "~codemirror/lib/codemirror.css";
@import "~codemirror/addon/lint/lint.css";
@import "~codemirror/addon/hint/show-hint.css";
@import "src/assets/scss/_buttons.scss";
@import "src/assets/scss/_forms-.scss";
@import "src/assets/scss/_fonts.scss";
@import "src/assets/scss/_colors.scss";
@import "src/assets/scss/_miscalleneous.scss";
@import "src/assets/scss/_spacings.scss";
@import "src/assets/scss/_alerts.scss";
@import "src/assets/scss/_checkbox.scss";
@import "src/assets/scss/_usermanager.scss";
@import "src/assets/scss/_filemanager.scss";
@import "src/assets/scss/_datamanager.scss";
@import "src/assets/scss/_radiobtn.scss";
@import "src/assets/scss/_upload-file.scss";
@import "src/assets/scss/_animations.scss";
@import "src/assets/scss/_table.scss";
@import "src/assets/scss/_variables.scss";
@import "src/assets/scss/tasks.scss";
@import "src/assets/scss/sidebar.scss";
@import "src/assets/scss/_setting.scss";
@import "src/assets/scss/_dashboard.scss";
@import "src/assets/scss/queries.scss";
@import "src/assets/scss/_dashboard.scss";
@import "src/assets/scss/_datadictionary.scss";
@import "src/assets/scss/_datasource.scss";
@import "src/assets/scss/_bigquery.scss";
@import "src/assets/scss/_functions.scss";
@import "src/assets/scss/_git.scss";
@import "src/assets/scss/white-labelling/kidm-style.scss";
@import "src/assets/scss/white-labelling/custom.scss";
@import "src/assets/scss/white-labelling/_kidm-user.scss";
* {
  margin: 0;
}

body {
  font-family: "Poppins", sans-serif !important;
  background-color: #f3f3f3;
  font-size: 15px;
}

h5,
h6,
h4,
h3 {
  color: rgba(0, 0, 0, 0.66);
}
h6 {
  font-size: 18px !important;
}
h3,
h5 {
  font-size: 18px !important;
}

.heading {
  font-size: 15px !important;
}
.sub-heading {
  font-size: 13px !important;
}
.content {
  font-size: 11px !important;
}
.ng-select span {
  font-size: 13px !important;
}
label {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.66);
}
input,
textarea {
  font-size: 12px !important;
}
small {
  font-size: 13px !important;
}
.text-black-0 {
  color: rgba(0, 0, 0, 0.66) !important;
}
.modal-header-font {
  font-size: 18px !important;
}

.cursor {
  cursor: pointer !important;
}

textarea {
  resize: none !important;
}

.toggle-view-off {
  width: 0 !important;
  transition: width 0.2s;
}

.toggle-view-on {
  width: 260px !important;
  transition: width 0.2s;
}

@media (max-width: 799px) {
  .toggle-icon {
    display: none;
  }

  .toggle-view-off {
    width: 260px !important;
    transition: width 0.2s;
  }
}

.filter-dropdown .dropdown-menu {
  width: 500px !important;
}

.filter-dropdown .dropdown-menu.show {
  left: -450px !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.fa-solid {
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none !important;
}
.header-dropdown .dropdown-menu.show {
  top: 10px !important;
}
.profile-dropdown {
  border: 1px solid $primary-color;
  display: inline-block;
  border-radius: 50%;
  padding: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.autocomplete-container .suggestions-container ul {
  max-height: 190px !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu {
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.back-icon {
  position: absolute;
  top: -30px;
  left: 0;
  transition: all 0.3s ease;

  &:hover {
    color: $primary-color;
  }
}

.CodeMirror {
  border: 1px solid #ced4da;
}
.codemirror-container {
  position: relative !important;
  z-index: -1 !important;
}
.CodeMirror-gutters {
  border-right: 1px solid #ddd !important;
  background-color: #f7f7f7 !important;
  white-space: nowrap !important;
}

/* Customize the scrollbar for WebKit browsers */
::-webkit-scrollbar {
  width: 5px;
  /* width of the scrollbar */
  height: 8px;
  /* height of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  /* color of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* color of the handle when hovered */
}

.docSearch::-webkit-scrollbar {
  width: 9px;
  /* width of the scrollbar */
  // height: 10px;
  /* height of the scrollbar */
}

/* Track */
.docSearch::-webkit-scrollbar-track {
  background: #dadada;
  /* color of the track */
}

/* Handle */
.docSearch::-webkit-scrollbar-thumb {
  background: #a3a3a3;
  /* color of the handle */
}

/* Handle on hover */
.docSearch::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* color of the handle when hovered */
}


// multiselect dropdown css
.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid $primary-color !important;
  background: $primary-color !important;
  padding: 0 8px !important;
  border-radius: 5px !important;
}
.multiselect-dropdown .dropdown-btn span {
  font-size: 13px !important;
}

.multiselect-item-checkbox {
  font-size: 13px !important;
}
.multiselect-item-checkbox input[type="checkbox"] + div:before {
  color: $primary-color !important;
  border: 2px solid $primary-color !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  background: $primary-color !important;
}
.ng-touched.ng-invalid {
  border-color: red !important;
}
.data-manager-pagination .pagination {
  margin-bottom: 0 !important;
}
.pagination {
  margin-bottom: 0 !important;
}

.btn-refresh-tabs {
  position: absolute !important;
  top: -73px !important;
  right: 18px !important;
}
.btn-save-tabs {
  position: absolute !important;
  top: -83px !important;
  right: 54px !important;
}

.query-prop-api .btn-save-tabs {
  position: absolute !important;
  top: -73px !important;
  right: 74px !important;
}
.modal-cross-btn {
  background-color: transparent !important;
  border: none !important;
  font-size: 24px !important;
}
.btn-refresh-user-access {
  position: absolute;
  top: -91px;
  right: 18px;
}

.dropdown-item-padd .dropdown-item {
  padding: 0.65rem 1rem !important;
  font-weight: 400 !important;
  text-align: inherit !important;
  white-space: nowrap !important;
  font-size: 15px !important;
}
.action-cursor {
  pointer-events: none;
}

.user-access-role-scroller .dropdown-list {
  overflow: hidden !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  right: -6px !important;
}

.as-horizontal > .as-split-gutter > .as-split-gutter-icon {
  background-image: none !important;
}
.as-horizontal > .as-split-gutter {
  background-color: #eee !important;
  &:hover {
    background-color: $primary-color !important;
  }
}
.trash-mode {
  background-color: #c62828;
  color: #fff;
  position: fixed;
  top: 12px;
  z-index: 999;
  right: 125px;
  padding: 8px;
  border-radius: 8px;
}

// **************. sidebar and right part icon styling while opening and closing sections *****************//

.right-part {
  margin-left: 240px !important;
  z-index: 1 !important;
}

@media (max-width: 800px) {
  .right-part {
    margin-left: 0 !important;
    z-index: 1 !important;
  }
}

.queries-right-part {
  margin-left: 240px !important;
}

.sys-classes {
  margin-left: 5px;
  cursor: pointer;
}

.query-header {
  padding: 12px !important;
}
/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

.list-details-accordion .card-header {
  padding: 13.5px 16px !important;
  margin-bottom: 0;
  background-color: #2088e7 !important;
  border-bottom: 0px solid transparent;
}
.btn-link1 {
  color: #212529 !important;
  text-decoration: none !important;
  font-weight: 600;
  padding: 5px 10px !important;
  font-size: 15px !important;
  span {
    font-size: 15px !important;
  }
}
.btn-link {
  color: #fff !important;
  text-decoration: none !important;
  font-weight: 600 !important;
  padding: 5px 0px !important;
}
.CodeMirror {
  height: calc(100vh - 195px) !important;
}
app-code .CodeMirror {
  height: calc(100vh - 120px) !important;
}
.modal-body .CodeMirror {
  height: calc(100vh - 133px) !important;
}
.json-editor .modal-dialog,
.json-editor .modal-content {
  width: 100vw !important;
  height: 100vh !important;
  margin: 0 !important;
}
.full-window .modal-dialog,
.full-window .modal-content {
  width: 100vw !important;
  margin: 0 !important;
}
.custom-full-width .modal-dialog,
.custom-full-width .modal-content {
  height: 100vh !important;
}
.toolbar-group-buttons {
  display: flex;
  gap: 10px;
  top: 85px;
  right: 10px;
  position: fixed;
}
app-json , app-attributes, .toolbar-group-buttons {
  top: 116px;
}
app-function,  app-schedules, .toolbar-group-buttons {
  top: 85px;
}

.data-table .toolbar-group-buttons {
  right: 40px;
}
.data-source-toolbar-buttons {
  display: flex;
  gap: 10px;
  top: 116px;
  right: 10px;
  position: fixed;
}
.manage-table .data-source-toolbar-buttons{
  right: 40px;
}
.data-table .data-source-toolbar-buttons{
  right: 40px;
}

.manage-table .toolbar-group-buttons {
  right: 42px !important;
}
.toolbar-group-buttons .dropdown-menu-right {
  position: absolute !important;
  top: 37px !important;
  right: 13px !important;
  z-index: 1111 !important;
}
.edit-icon {
  color: #218ae7;
}

.db-property-scroller {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 200px);
}
.logo-icon {
  left: 40px;
  top: 30px;
  height: 50px;
}

.searchModel .title {
  transition: 0 ease-in;
  font-weight: 500;
  font-size: 18px;
  color: #4b86e5;
}
.searchModel .title:hover {
  text-decoration: underline !important;
}
.font-16 {
  font-size: 16px;
}
.font-14 {
  font-size: 14px;
}
.font-12 {
  font-size: 12px;
}
.fw-500 {
  font-weight: 500;
}
.lh-15 {
  line-height: 18px;
}
@media (max-width: 1320px) {
  .bread-buttons {
    display: flex;
  }
}
table thead {
  background-color: #f8f8f8 !important;
  color: #000 !important;
}
table thead th {
  font-size: 12px;
  font-weight: 600;
}
table td {
  font-size: 11px;
  font-weight: 400;
  span {
    font-size: 11px !important;
  }
}
.modal-header .modal-title {
  font-size: 13px !important;
}
.modal-header .btn-close {
  font-size: 13px !important;
}

// by default the component is not visible
.spinner-component {
  display: none;
}

// when body has loading class attached the component becomes visible in the right corner of the page
body.loading {
  div.spinner-component {
    display: block;
    position: absolute !important;
    top: 15px;
    right: 65px;
    bottom: 0px;
    width: 24px;
    height: 24px;
    z-index: 999;
  }
}


.loader {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  overflow: hidden;
}
.loader::after {
  content: '';
  width: 192px;
  height: 4.8px;
  background: #1e88e5;
  position: fixed;
  top: 65px;
  left: 0;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}

.ng-select .ng-select-container {
  border-radius: 0.375rem !important;
}

.team-members .dropdown,
.users-table .dropdown {
  position: unset !important;
}

@media (max-width: 768px) {
  .toolbar-dropdown .dropdown-menu.show {
    left: -320px !important;
  }
}
::placeholder {
  opacity: 0.3 !important;
  color: currentColor !important;
  font-size: 12px;
}
.ng-placeholder{
  opacity: 0.3 !important;
  color: currentColor !important;
  font-size: 12px !important;
}
.bg-ED {
  background-color: #ededed !important;
}

// accordian css
.accordion-button::after {
  transition: none !important;
  position: absolute !important;
  right: 8px !important;
  bottom: 16px !important;
}
.accordion-button:not(.collapsed)::after {
  transform: none !important;
  position: absolute !important;
  right: 15px !important;
  bottom: 16px !important;
}
as-split-area {
  overflow: hidden !important;
}
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-radius: 0 !important;
}
.modal-content,
.card,
.form-select {
  border-radius: 0 !important;
}

.form-group-checkbox-header {
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  label {
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      background-color: transparent;
      border: 2px solid #ffffff;
      border-radius: 3px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px !important;
    }
  }
  input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 7px;
    width: 6px;
    height: 13px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
.black-updown-icon {
  filter: brightness(0%);
}
.shareFolderModal {
  .dropdown-list {
    overflow-y: hidden !important;
  }
}
.cursor-default {
  cursor: default !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.text-justify {
  text-align: justify;
}
.readOnly {
  background-color: #c1c1c1 !important;
  opacity: 0.7 !important;
}
.btn-sm {
  font-size: 11px !important;
}
.selectedRow {
  background-color: #1e88e5 !important ;
  color: #fff !important;
}

.modal-body .as-horizontal > .as-split-gutter {
  height: calc(100vh - 68px) !important;
}

.modal-body .as-horizontal > .as-split-area {
  height: auto !important;
}
.text-decoration {
  text-decoration: underline;
  &:hover {
    color: #1e88e5 !important;
  }
}
.breadcrumb-item a {
  color: #1f88e6 !important;
  text-decoration: none !important;
  font-weight: 500 !important;
}
.dashboard-view-btn {
  position: absolute;
  right: 90px;
  top: -46px;
  z-index: 12;
  border-radius: 50% !important;
  border: 1px solid #1e88e5 !important;
  width: 30px;
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  i {
    color: #1e88e5;
    font-size: 13px;
    margin: 0;
  }
}
.search-button{
  right: 130px;
}
.icons-container i {
  margin: 0 10px;
  font-size: 20px;
  color: $primary-color;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.bookmark-heading {
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    text-decoration: underline;
    color: #1e88e5;
  }
}
.sftp-headings {
  color: #1e88e5;
}
.alert-info-background {
  padding: 16px !important;
  background-color: #cff4fc !important;
}
.trending-file-thumbnail {
  padding: 10px;
  background: #1e88e51a;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  width: 40px;
  margin-right: 10px;
}
.tabs-section .properties .nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #1e88e5 !important;
  border: 0 !important;
}

.btn-outline-info {
  color: #6c757d !important;
  border-color: #6c757d !important;
  &:hover,
  &:active {
    color: #fff !important;
    background-color: #1e88e5 !important;
    border-color: #1e88e5 !important;
  }
}

.search-filter-dropdown {
  border: 1px solid #1e88e5 !important;
  background-color: #1e88e5 !important;
  color: #fff;
  padding: 4px 8px;
  font-size: 13px;
  margin-left: 28px;
}
.sidebar-btn.delete-btn {
  background-color: #dc2f2f !important;
  border-color: #dc2f2f !important;
  border-radius: 0;
}
.theme-green .bs-datepicker-head {
  background-color: #1e88e5;
}
.theme-green .bs-datepicker-body table td.week span {
  color: #1e88e5;
}
.doc-logo {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid #2088e5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active-card {
  background-color: #2088e5 !important;
  color: #fff !important;
  border: 1px solid #2088e5 !important;
}

.error-box {
  .error-title {
    font-size: 210px;
    color: #2088e6;
    font-weight: 900;
    text-shadow: 4px 4px 0 #fff, 6px 6px 0 #2088e6;
    line-height: 210px;
  }
}
.not-found-error-btn {
  color: #fff !important;
  background-color: #2088e5 !important;
  border: 1px solid #2088e5 !important;
}
.doc-response-card {
  display: inline-block;
  border: 1px solid #2088e5 !important;
  border-radius: 50%;
  width: 35px;
  padding: 5px 12px;
  background-color: #2088e5 !important;
  color: #fff;
}
span.cm-comment{
  color:grey !important;
  }


  .highlight{
    // background-color: #1e88e53d;
    border: 3px dotted #2088e5 !important;
    position: relative;

    .overlay{
        width: 100%;
        background: #1e88e53d;
        height: 100%;
        position: absolute;
        opacity: 0.9;
        z-index: 111111;
        input{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
    }
    .drag-text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        align-items: center;
        font-size: 18px;
        width: 40%;
        margin: 0 auto;
        padding-bottom: 40px;
    }
    .fa-beat{
        font-size: 50px;
        color: #2088e5;
        cursor: pointer;
    }
}

@media (max-height: 1315px){
  .users-dropdown .dropdown-list{
    bottom: 45px !important;
}
}

@media (max-height: 1237px){
  .roles-dropdown .dropdown-list{
    bottom: 45px !important;
}
}


.highlighted-text {
  background-color: yellow !important;
}
.selected-text {
  background-color: lightblue !important; 
}
.selected-z{
  background-color: yellow !important;

}
.highlighted-row {
  border: 0.75px solid lightblue;
}
.support-text{
  font-size: 11px !important; 
}
app-document-data .pagination{
  z-index: 0 !important;
  position: relative;
}

.back-arrow-icon{
  font-weight: 800 !important;
  font-size: 16px !important;
  color: #1e88e5 !important;
  cursor: pointer;

}
.view-btn{
  border-radius: 50% !important;
  width: 30px;
  height: 30px;
  border: 1px solid #1f88e5 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  i{
    color: #1f88e5;
  }
}
markdown{
  li,p{
    font-size: 12px;
  }
}